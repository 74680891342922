<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div v-show="loading == true" class="alert alert-warning" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error" class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                </div><!-- /alert-danger -->
                <div class="btn-group w-100" role="group" v-if="loading == false">
                    <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                        <i class="fas fa-cogs mr-2" v-show="platform == 'desktop'"></i>
                        <span>Geral</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                        <i class="fas fa-tv mr-2" v-show="platform == 'desktop'"></i>
                        <span>Ao vivo</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="cat = 3" :class="{'active': cat == 3}" :disabled="cat == 3">
                        <i class="fas fa-images mr-2" v-show="platform == 'desktop'"></i>
                        <span>Imagens</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="cat = 4" :class="{'active': cat == 4}" :disabled="cat == 4">
                        <i class="fas fa-plus mr-2" v-show="platform == 'desktop'"></i>
                        <span>Mais</span>
                    </button>
                </div><!-- /btn-group -->
                <div class="mt-3 mb-3" v-if="loading == false">
                    <form @submit.prevent="save">
                        <template v-if="cat == 1">
                            <div class="form-group">
                                <label class="text-light">Nome da banca</label>
                                <input type="text" class="form-control" v-model="configs.nome" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Bloquear sistema</label>
                                <select class="form-control" v-model="configs.bloquear">
                                    <option value="1" :selected="configs.bloquear == 1">Sim</option>
                                    <option value="2" :selected="configs.bloquear == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Multiplicar valor apostado</label>
                                <input type="text" class="form-control" v-model="configs.multiplicador" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Mínimo de jogos por aposta</label>
                                <input type="text" class="form-control" v-model="configs.min_jogos" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Máximo de jogos por aposta</label>
                                <input type="text" class="form-control" v-model="configs.max_jogos" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Valor mínimo para realizar apostas</label>
                                <input type="text" class="form-control" v-model="configs.min_valor" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Valor máximo de pagamento da banca</label>
                                <input type="text" class="form-control" v-model="configs.max_valor" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Valor máximo permitido para um palpite</label>
                                <input type="text" class="form-control" v-model="configs.max_valor_um_palpite" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Valor máximo permitido para dois palpites</label>
                                <input type="text" class="form-control" v-model="configs.max_valor_dois_palpites" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Valor máximo permitido para três ou mais palpites</label>
                                <input type="text" class="form-control" v-model="configs.max_valor_mais_palpites" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Cotação mínima para realizar aposta com um palpite</label>
                                <input type="text" class="form-control" v-model="configs.min_valor_odd_um_palpite" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Cotação mínima para realizar aposta com dois ou mais palpites</label>
                                <input type="text" class="form-control" v-model="configs.min_valor_odd_mais_palpites" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Cotação com o valor menor que R$ {{configs.min_odd ? configs.min_odd : '0.00'}} serão bloqueadas</label>
                                <input type="text" class="form-control" v-model="configs.min_odd" autocomplete="off" spellcheck="false">
                            </div>
                            <!-- <div class="form-group">
                                <label class="text-light">Permitir realizar apostas com centavos</label>
                                <select class="form-control" v-model="configs.permitir_centavos">
                                    <option value="1" :selected="configs.permitir_centavos == 1">Sim</option>
                                    <option value="2" :selected="configs.permitir_centavos == 2">Não</option>
                                </select>
                            </div> -->
                            <div class="form-group">
                                <label class="text-light">Permitir que os gerentes excluam apostas</label>
                                <select class="form-control" v-model="configs.gerente_excluir_aposta">
                                    <option value="1" :selected="configs.gerente_excluir_aposta == 1">Sim</option>
                                    <option value="2" :selected="configs.gerente_excluir_aposta == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Permitir que os vendedores excluam apostas</label>
                                <select class="form-control" v-model="configs.vendedor_excluir_aposta">
                                    <option value="1" :selected="configs.vendedor_excluir_aposta == 1">Sim</option>
                                    <option value="2" :selected="configs.vendedor_excluir_aposta == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Tempo para que os gerentes e vendedores excluam apostas</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">Minutos</div>
                                    </div>
                                    <input type="text" class="form-control" v-model="configs.excluir_aposta_tempo" :readonly="configs.gerente_excluir_aposta == 2 && configs.vendedor_excluir_aposta == 2" autocomplete="off" spellcheck="false">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Exibir campeonato na impressão do comprovante</label>
                                <select class="form-control" v-model="configs.exibir_campeonato">
                                    <option value="1" :selected="configs.exibir_campeonato == 1">Sim</option>
                                    <option value="2" :selected="configs.exibir_campeonato == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Destacar campeonato na impressão com tarja preta</label>
                                <select class="form-control" v-model="configs.destacar_campeonato">
                                    <option value="1" :selected="configs.destacar_campeonato == 1">Sim</option>
                                    <option value="2" :selected="configs.destacar_campeonato == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Exibir telefone do vendedor no comprovante</label>
                                <select class="form-control" v-model="configs.exibir_telefone_vendedor">
                                    <option value="1" :selected="configs.exibir_telefone_vendedor == 1">Sim</option>
                                    <option value="2" :selected="configs.exibir_telefone_vendedor == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Mensagem informativa do final do comprovante</label>
                                <textarea class="form-control" v-model="configs.mensagem" rows="3" autocomplete="off" spellcheck="false"></textarea>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Termos e regulamento da sua banca</label>
                                <textarea class="form-control" v-model="configs.regras" rows="10" autocomplete="off" spellcheck="false"></textarea>
                            </div>
                        </template>
                        <template v-else-if="cat == 2">
                            <div class="form-group">
                                <label class="text-light">Permitir jogos ao vivo</label>
                                <select class="form-control" v-model="configs.ao_vivo">
                                    <option value="1" :selected="configs.ao_vivo == 1">Sim</option>
                                    <option value="2" :selected="configs.ao_vivo == 2">Não</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="text-light">Mínimo de jogos por aposta</label>
                                <input type="text" class="form-control" v-model="configs.ao_vivo_min_jogos" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="form-group">
                                <label class="text-light">Tempo máximo para apostas</label>
                                <input type="text" class="form-control" v-model="configs.ao_vivo_tempo" :readonly="configs.ao_vivo == 2" autocomplete="off" spellcheck="false">
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped table-dark table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Opção</th>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Limite</th>
                                            <th scope="col">Bloquear</th>
                                            <th scope="col">Porcentagem</th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in configs.ao_vivo_opcoes" :key="index">
                                            <td class="align-middle text-nowrap">{{item.nome}}</td>
                                            <td>
                                                <select class="form-control w-auto" v-model="configs.ao_vivo_opcoes[index].tipo">
                                                    <option value="1" :selected="item.tipo == 1">Reduzir</option>
                                                    <option value="2" :selected="item.tipo == 2">Aumentar</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control w-auto w-limit" type="text" v-model="configs.ao_vivo_opcoes[index].limite" :id="`limit-${index}`" v-number-only @paste="noPaste($event)" autocomplete="off" spellcheck="false"> 
                                            </td>
                                            <td>
                                                <select class="form-control w-auto" v-model="configs.ao_vivo_opcoes[index].ativo">
                                                    <option value="1" :selected="item.ativo == 1">Não</option>
                                                    <option value="2" :selected="item.ativo == 2">Sim</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control w-auto" type="text" v-model="configs.ao_vivo_opcoes[index].porcentagem" :id="`percent-${index}`" v-number-only @paste="noPaste($event)" autocomplete="off" spellcheck="false"> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else-if="cat == 3">
                            <ul class="nav nav-pills nav-fill mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript:;" @click="upload_step = 0" :class="{'disabled': upload_progress, 'active': upload_step == 0}">Slider</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="javascript:;" @click="upload_step = 1" :class="{'disabled': upload_progress, 'active': upload_step == 1}">Popup</a>
                                </li>
                            </ul>
                            <template v-if="upload_step == 0">
                                <div class="card text-center mb-3">
                                    <div class="card-header">Slider</div>
                                        <div class="card-body">
                                        <h5 class="card-title">Envie suas imagens</h5>
                                        <p class="card-text">Essas são as dimensões das imagens do slider: 1993x308 px</p>
                                        <div class="card-columns">
                                            <div class="card" v-for="(item, index) in configs.slider" :key="index">
                                                <img :src="item.image" class="card-img-top" alt="">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col" :class="{'col-12': platform == 'mobile'}">
                                                            <a class="btn btn-danger btn-block text-white" role="button" @click="deleteImage(index)"><i class="fas fa-trash-alt mr-2"></i>Excluir</a>
                                                        </div>
                                                        <div class="col" :class="{'col-12 mt-3': platform == 'mobile'}">
                                                            <a class="btn btn-secondary btn-block text-white" role="button" @click="linkImage(index)"><i class="fas fa-link mr-2"></i>Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- /card -->
                                        </div><!-- /card-columns -->
                                    </div>
                                    <div class="card-footer">
                                        <div class="progress mb-3" style="height: 20px;" v-show="upload_step == 0 && upload_progress == true">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" :style="{'width': progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                                        </div>
                                        <div class="form-group mb-0" role="file">
                                            <div class="row" :class="{'pl-3 pr-3': platform == 'mobile'}">
                                                <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                    <label class="btn btn-primary btn-block mb-0" for="image-upload" :disabled="upload_progress == true">
                                                        <input type="file" accept=".gif, .jpg, .jpeg, .png" id="image-upload" @change="upload">
                                                        <i class="fas fa-upload mr-2"></i>
                                                        <span>Enviar imagem</span>
                                                    </label>
                                                </div><!-- /col -->
                                                <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                    <select class="form-control" v-model="configs.slider_exibir" :class="{'mt-3': platform == 'mobile'}">
                                                        <option value="1" :selected="configs.slider_exibir == 1">Exibir</option>
                                                        <option value="2" :selected="configs.slider_exibir == 2">Não exibir</option>
                                                    </select>
                                                </div>
                                            </div><!-- /row -->
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="card text-center mb-3">
                                <div class="card-header">Popup</div>
                                    <div class="card-body">
                                        <h5 class="card-title">Envie sua imagem</h5>
                                        <p class="card-text">Você só pode enviar uma imagem com o tamanho: 250x400 px</p>
                                        <div class="card-columns">
                                            <div class="card" v-for="(item, index) in configs.popup_items" :key="index">
                                                <img :src="item.image" class="card-img-top" alt="">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col" :class="{'col-12': platform == 'mobile'}">
                                                            <a class="btn btn-danger btn-block text-white" role="button" @click="deleteImage(index)"><i class="fas fa-trash-alt mr-2"></i>Excluir</a>
                                                        </div>
                                                        <div class="col" :class="{'col-12 mt-3': platform == 'mobile'}">
                                                            <a class="btn btn-secondary btn-block text-white" role="button" @click="linkImage(index)"><i class="fas fa-link mr-2"></i>Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><!-- /card -->
                                        </div><!-- /card-columns -->
                                    </div>
                                    <div class="card-footer">
                                        <div class="progress mb-3" style="height: 20px;" v-show="upload_step == 1 && upload_progress == true">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" :style="{'width': progress + '%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{progress}}%</div>
                                        </div>
                                        <div class="form-group mb-0" role="file">
                                            <div class="row" :class="{'pl-3 pr-3': platform == 'mobile'}">
                                                <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                    <label class="btn btn-primary btn-block mb-0" for="image-upload" :disabled="upload_progress == true">
                                                        <input type="file" accept=".gif, .jpg, .jpeg, .png" id="image-upload" @change="upload">
                                                        <i class="fas fa-upload mr-2"></i>
                                                        <span>Enviar imagem</span>
                                                    </label>
                                                </div><!-- /col -->
                                                <div class="col" :class="{'d-contents': platform == 'mobile'}">
                                                    <select class="form-control" v-model="configs.popup_exibir" :class="{'mt-3': platform == 'mobile'}">
                                                        <option value="sim" :selected="configs.popup_exibir == 'sim'">Exibir</option>
                                                        <option value="nao" :selected="configs.popup_exibir == 'nao'">Não exibir</option>
                                                    </select>
                                                </div>
                                            </div><!-- /row -->
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <ul class="nav nav-pills mb-3 myTabConfigs" :class="{'nav-justified': platform == 'desktop', 'flex-column': platform == 'mobile'}" id="myTabConfigs" role="tablist">
                                <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                    <a class="nav-link active" :class="{'rounded-left': platform == 'desktop', 'rounded': platform == 'mobile'}" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">Travas</a>
                                </li>
                                <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                    <a class="nav-link" :class="{'rounded-0': platform == 'desktop', 'rounded': platform == 'mobile'}" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false" @click="getBanks">Cliente</a>
                                </li>
                                <li class="nav-item" :class="{'mb-2': platform == 'mobile'}">
                                    <a class="nav-link" :class="{'rounded-0': platform == 'desktop', 'rounded': platform == 'mobile'}" id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="third" aria-selected="false">Redes sociais</a>
                                </li>  
                                <li class="nav-item">
                                    <a class="nav-link" :class="{'rounded-right': platform == 'desktop', 'rounded': platform == 'mobile'}" id="fourth-tab" data-toggle="tab" href="#fourth" role="tab" aria-controls="fourth" aria-selected="false">Encerrar aposta</a>
                                </li>                       
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                    <div class="form-group">
                                        <label class="text-light">Data trava</label>
                                        <input type="date" class="form-control" v-model="configs.data_trava">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Exibir eventos até</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">Dias</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="configs.exibir_eventos_limite" autocomplete="off" spellcheck="false">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Exibir caixa do vendedor até</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">Dias</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="configs.exibir_caixa_limite" autocomplete="off" spellcheck="false">
                                        </div>
                                    </div>
                                    <div class="pt-2 pb-2">
                                        <h3 class="text-center text-light text-uppercase">Alerta de aposta</h3>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">E-mail</label>
                                        <input type="email" class="form-control" placeholder="Informe um e-mail" v-model="configs.notificacoes.email" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor</label>
                                        <input type="text" class="form-control" placeholder="Informe um valor" v-model="configs.notificacoes.valor" autocomplete="off" spellcheck="false">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Número de palpites</label>
                                        <input type="text" class="form-control" placeholder="Informe o número de palpites" v-model="configs.notificacoes.palpites" autocomplete="off" spellcheck="false">
                                    </div>
                                </div><!-- /tab-pane -->
                                <div class="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                                    <div class="form-group">
                                        <label class="text-light">Permitir cadastro de clientes</label>
                                        <select class="form-control" v-model="configs.permitir_cadastro_clientes">
                                            <option value="1" :selected="configs.permitir_cadastro_clientes == 1">Sim</option>
                                            <option value="2" :selected="configs.permitir_cadastro_clientes == 2">Não</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor mínimo do saque</label>
                                        <input type="text" class="form-control" v-model="configs.valor_minimo_saque" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Valor mínimo do depósito</label>
                                        <input type="text" class="form-control" v-model="configs.valor_minimo_deposito" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Comissão por indicação (Afiliados)</label>
                                        <input type="text" class="form-control" v-model="configs.comissao_por_indicacao" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">E-mail para receber as notificações</label>
                                        <input type="email" class="form-control" v-model="configs.email_notificacoes" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                    </div>
                                    <div class="form-group">
                                        <label class="text-light">Mensagem informativa sobre os pagamentos</label>
                                        <textarea class="form-control" v-model="configs.mensagem_pagamentos" rows="3" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2"></textarea>
                                    </div>
                                    <h3 class="text-center text-light text-uppercase pt-2 pb-2">Dados bancários</h3>
                                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Selecione o banco</label>
                                                <div class="w-100">
                                                    <div id="virtual-select" data-style="form-control"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Tipo de conta</label>
                                                <select class="form-control" v-model="configs.dados_bancarios.tipo" :disabled="configs.permitir_cadastro_clientes == 2">
                                                    <option value="Corrente" :selected="configs.dados_bancarios.tipo == 'Corrente'">Corrente</option>
                                                    <option value="Poupança" :selected="configs.dados_bancarios.tipo == 'Poupança'">Poupança</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light w-100">
                                                    <span>Tipo de chave pix</span>
                                                    <div class="float-right text-warning">Opcional</div>
                                                </label>
                                                <select class="form-control" v-model="configs.dados_bancarios.pix_tipo" :disabled="configs.permitir_cadastro_clientes == 2">
                                                    <option value="cpf_cnpj" :selected="configs.dados_bancarios.pix_tipo == 'cpf_cnpj'">CPF/CNPJ</option>
                                                    <option value="email" :selected="configs.dados_bancarios.pix_tipo == 'email'">E-mail</option>
                                                    <option value="celular" :selected="configs.dados_bancarios.pix_tipo == 'celular'">Celular</option>
                                                    <option value="chave" :selected="configs.dados_bancarios.pix_tipo == 'chave'">Chave aleatória</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light w-100">
                                                    <span>PIX</span>
                                                    <div class="float-right text-warning">Opcional</div>
                                                </label>
                                                <template v-if="configs.dados_bancarios.pix_tipo == 'cpf_cnpj'">
                                                    <the-mask class="form-control" v-model="configs.dados_bancarios.pix" :mask="['###.###.###-##', '##.###.###/####-##']" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                                </template>
                                                <template v-else-if="configs.dados_bancarios.pix_tipo == 'celular'">
                                                    <the-mask class="form-control" v-model="configs.dados_bancarios.pix" :mask="'(##) # ####-####'" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                                </template>
                                                <template v-else>
                                                    <input :type="configs.dados_bancarios.pix_tipo == 'email' ? 'email' : 'text'" class="form-control" v-model="configs.dados_bancarios.pix" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Agência</label>
                                                <input type="text" class="form-control" v-model="configs.dados_bancarios.agencia" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Conta</label>
                                                <input type="text" class="form-control" v-model="configs.dados_bancarios.conta" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Dígito</label>
                                                <input type="text" class="form-control" v-model="configs.dados_bancarios.digito" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Nome</label>
                                                <input type="text" class="form-control" v-model="configs.dados_bancarios.nome" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">CPF/CNPJ</label>
                                                <the-mask class="form-control" v-model="configs.dados_bancarios.cpf_cnpj" :mask="['###.###.###-##', '##.###.###/####-##']" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">WhatsApp</label>
                                                <the-mask class="form-control" v-model="configs.dados_bancarios.telefone" :mask="'(##) ####-####'" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2" />
                                            </div>
                                        </div>
                                    </div> 
                                    <h3 class="text-center text-light text-uppercase pt-2 pb-2">Cupom promocional</h3>
                                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Código</label>
                                                <input type="text" class="form-control" v-model="configs.cupom_promocional_codigo" maxlength="15" placeholder="Crie seu código do cupom" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Data de expiração</label>
                                                <input type="date" class="form-control" v-model="configs.cupom_promocional_data" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row" :class="{'d-block': platform == 'mobile'}">
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">{{configs.cupom_promocional_tipo == 'valor' ? 'Valor' : 'Porcentagem'}}</label>
                                                <input type="text" class="form-control" v-model="configs.cupom_promocional_valor" :placeholder="configs.cupom_promocional_tipo == 'valor' ? 'Informe o valor' : 'Informe a porcentagem'" autocomplete="off" spellcheck="false" :readonly="configs.permitir_cadastro_clientes == 2">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label class="text-light">Tipo de bonificação</label>
                                                <select class="form-control" v-model="configs.cupom_promocional_tipo" :disabled="configs.permitir_cadastro_clientes == 2">
                                                    <option value="porcentagem" :selected="configs.cupom_promocional_tipo == 'porcentagem'">Porcentagem</option>
                                                    <option value="valor" :selected="configs.cupom_promocional_tipo == 'valor'">Valor</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <h3 class="text-center text-light text-uppercase pt-2 pb-2">Links de pagamento</h3>
                                    <div class="card text-center mb-3">
                                        <div class="card-body">
                                            <template v-if="configs.links_de_pagamentos.length == 0">
                                                <div class="card-text">Nenhum link adicionado!</div>
                                            </template>
                                            <template v-else>
                                                <div class="card-columns">
                                                    <div class="card" v-for="(item, index) in configs.links_de_pagamentos" :key="index">
                                                        <div class="card-body">
                                                            <h3 class="card-title text-center">R$ {{item.valor | formatCoin}}</h3>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <a role="button" class="btn btn-danger btn-block text-white" @click="deletePaymentLink(index, item.valor)">
                                                                        <i class="fas fa-trash-alt mr-2"></i>Excluir
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="card-footer">
                                            <a role="button"  class="btn btn-block btn-primary text-white" @click="addPaymentLink">
                                                <i class="fas fa-link mr-2"></i>Adicionar link
                                            </a>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">
                                    <label class="text-light" for="basic-3">Instagram</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon3">
                                                <i class="fab fa-instagram"></i>
                                            </span>
                                        </div>
                                        <input type="text" class="form-control" id="basic-3" aria-describedby="basic-addon3" v-model="configs.redes_sociais.instagram" placeholder="Informe o usuário" spellcheck="false">
                                    </div>
                                    <label class="text-light" for="basic-4">WhatsApp</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon3">
                                                <i class="fab fa-whatsapp"></i>
                                            </span>
                                        </div>
                                        <the-mask class="form-control" id="basic-4" aria-describedby="basic-addon3" v-model="configs.redes_sociais.whatsapp" :mask="'(##) # ####-####'" placeholder="Informe o número" autocomplete="off" spellcheck="false" />
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="fourth" role="tabpanel" aria-labelledby="fourth-tab">
                                    <label class="text-light" for="basic-5">Permitir encerramento de apostas</label>
                                    <div class="input-group mb-3">
                                        <select class="form-control" id="basic-5" v-model="configs.encerrar_aposta">
                                            <option value="sim" :selected="configs.encerrar_aposta == 'sim'">Sim</option>
                                            <option value="nao" :selected="configs.encerrar_aposta == 'nao'">Não</option>
                                        </select>
                                    </div>
                                    <label class="text-light" for="basic-6">Porcentagem de encerramento da empresa</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" id="basic-6" aria-describedby="basic-addon3" v-model="configs.encerrar_aposta_porcentagem" placeholder="Informe a porcentagem">
                                    </div>
                                    <label class="text-light" for="basic-7">Mínimo de palpites pendentes para o encerramento</label>
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" id="basic-7" aria-describedby="basic-addon3" v-model="configs.encerrar_aposta_num_palpites" placeholder="Informe o número de palpites">
                                    </div>
                                </div>
                            </div><!-- /tab-content -->
                        </template>
                        <button class="btn btn-warning btn-block" type="submit">
                            <i class="fas fa-save mr-2"></i>Salvar alterações
                        </button>
                    </form>
                </div><!-- /mt-3 mb-3 -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="bg_loading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import {TheMask} from 'vue-the-mask'
import Loading from 'vue-loading-overlay'
import $ from 'jquery/dist/jquery.min.js'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

export default {
    data() {
        return {
            cat: 1,
            error: '',
            banks: [],
            progress: 0,
            configs: {
                nome: '',
                bloquear: '',
                min_odd: '',
                min_jogos: '',
                max_jogos: '',
                min_valor: '',
                max_valor: '',
                multiplicador: '',
                permitir_centavos: '',
                exibir_campeonato: '',
                destacar_campeonato: '',
                excluir_aposta_tempo: '',
                gerente_excluir_aposta: '',
                vendedor_excluir_aposta: '',
                max_valor_um_palpite: '',
                max_valor_dois_palpites: '',
                max_valor_mais_palpites: '',
                min_valor_odd_um_palpite: '',
                min_valor_odd_mais_palpites: '',
                regras: '',
                mensagem: '',
                ao_vivo: '',
                ao_vivo_tempo: '',
                ao_vivo_min_jogos: '',
                ao_vivo_opcoes: [],
                slider: [],
                slider_exibir: '',
                popup_exibir: 'nao',
                popup_items: [],
                data_trava: '',
                exibir_caixa_limite: 15,
                exibir_telefone_vendedor: 1,
                adiar_eventos: 1,
                adiar_eventos_tempo: 24,
                exibir_eventos_limite: 3,
                permitir_cadastro_clientes: 2,
                valor_minimo_saque: 100,
                valor_minimo_deposito: 50,
                links_de_pagamentos: [],
                email_notificacoes: '',
                mensagem_pagamentos: '',
                comissao_por_indicacao: 10,
                encerrar_aposta: 'nao',
                encerrar_aposta_porcentagem: 0,
                encerrar_aposta_num_palpites: 1,
                cupom_promocional_codigo: '',
                cupom_promocional_data: '',
                cupom_promocional_valor: 0,
                cupom_promocional_tipo: 'porcentagem',
                notificacoes: {
                    email: '',
                    valor: 50,
                    palpites: 1
                },
                redes_sociais: {
                    twitter: '',
                    facebook: '',
                    whatsapp: '',
                    instagram: ''
                },
                dados_bancarios: {
                    pix: '',
                    pix_tipo: 'cpf_cnpj',
                    nome: '',
                    tipo: '',
                    cpf_cnpj: '',
                    agencia: '',
                    conta: '',
                    digito: '',
                    banco: '',
                    telefone: ''
                }
            },
            upload_step: 0,
            loading: false,
            bg_loading: false,
            upload_progress: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        TheMask,
        Loading
    },
    methods: {
        save() {
            
            const self = this;
            const regExp = /[a-zA-Z]/g;
            const filter = self.configs.ao_vivo_opcoes.some(item => item.limite.toString().trim().length == 0 || item.porcentagem.toString().trim().length == 0 || regExp.test(item.limite.toString().trim()) || regExp.test(item.porcentagem.toString().trim()));

            if (filter || self.configs.nome.trim() == '' || self.configs.multiplicador == '' || self.configs.min_jogos == '' || self.configs.max_jogos == '' || self.configs.min_valor == '' || self.configs.max_valor == '' || self.configs.max_valor_um_palpite == '' || self.configs.max_valor_dois_palpites == '' || self.configs.max_valor_mais_palpites == '' || self.configs.min_valor_odd_um_palpite == '' || self.configs.min_valor_odd_mais_palpites == '' || self.configs.excluir_aposta_tempo == '' || self.configs.mensagem.trim() == '' || self.configs.regras.trim() == '' || self.configs.ao_vivo_tempo == '' || self.configs.data_trava == '' || self.configs.exibir_caixa_limite == '' || self.configs.min_odd == '') {
                Swal.fire(
                    'Atenção!',
                    'Preencha todos os campos antes de salvar!',
                    'warning'
                );
            }
            else if (self.configs.permitir_cadastro_clientes == '1' && (self.configs.email_notificacoes.trim() == '' || self.configs.mensagem_pagamentos.trim() == '' || self.configs.dados_bancarios.banco == '' || self.configs.dados_bancarios.nome == '' || self.configs.dados_bancarios.agencia == '' || self.configs.dados_bancarios.conta == '' || self.configs.dados_bancarios.digito == '' || self.configs.dados_bancarios.cpf_cnpj == '' || self.configs.dados_bancarios.telefone == '')) {
                Swal.fire(
                    'Atenção!',
                    'Preencha todos os campos antes de salvar!',
                    'warning'
                );
            }
            else if (self.configs.permitir_cadastro_clientes == '1' && !self.validateEmail(self.configs.email_notificacoes)) {
                Swal.fire(
                    'Atenção!',
                    'O endereço de e-mail fornecido é inválido!',
                    'warning'
                );
            }
            else if (self.configs.notificacoes.email.toString().trim() != '' && !self.validateEmail(self.configs.notificacoes.email)) {
                Swal.fire(
                    'Atenção!',
                    'O endereço de e-mail fornecido em alertas de aposta é inválido!',
                    'warning'
                );
            }
            else if (self.configs.notificacoes.valor.toString().trim() == '' || self.configs.notificacoes.valor == 0) {
                Swal.fire(
                    'Atenção!',
                    'O campo "valor" em alertas de aposta não pode ficar em branco!',
                    'warning'
                );
            }
            else if (self.configs.notificacoes.palpites.toString().trim() == '' || self.configs.notificacoes.palpites == 0) {
                Swal.fire(
                    'Atenção!',
                    'O campo "número de palpites" em alertas de aposta não pode ficar em branco!',
                    'warning'
                );
            }
            else if (self.configs.cupom_promocional_valor.toString() == '') 
            {
                Swal.fire(
                    'Atenção!',
                    'O valor não pode ficar vázio. <br /> Informe algum valor ou caso queira desativar, coloque: "0".',
                    'warning'
                );
            }
            else if (self.configs.cupom_promocional_valor != 0 && self.configs.cupom_promocional_codigo == '')
            {
                Swal.fire(
                    'Atenção!',
                    'Informe um código para a criação do cupom!',
                    'warning'
                );
            }
            else if (self.configs.cupom_promocional_valor != 0 && self.configs.cupom_promocional_data == '')
            {
                Swal.fire(
                    'Atenção!',
                    'Informe a data de expiração do cupom!',
                    'warning'
                );
            }
            else if (self.configs.cupom_promocional_codigo && self.configs.cupom_promocional_valor == 0)
            {
                Swal.fire(
                    'Atenção!',
                    `Informe ${self.configs.cupom_promocional_tipo == 'valor' ? 'o valor' : 'a porcentagem'} do cupom!`,
                    'warning'
                );
            }
            else 
            {
                self.bg_loading = true;

                api.post('dashboard/configs', {
                    configs: self.configs
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.bg_loading = false;
                });
            }
        },
        upload() {
           
           const self = this;
           const imagem = document.getElementById('image-upload').files[0];

           if (typeof(imagem) == 'object') {

               if (imagem.type == 'image/jpg' || imagem.type == 'image/jpeg' || imagem.type == 'image/gif' || imagem.type == 'image/png') {
                    
                    self.progress = 0;
                    self.upload_progress = true;

                    let formData = new FormData();
                        formData.append('imagem', imagem);

                    api.post('dashboard/upload', formData , {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        transformRequest: [(data) => {
                            return data
                        }],
                        onUploadProgress: (progressEvent) => {
                            let totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                            if (totalLength !== null) {
                                self.progress = Math.round((progressEvent.loaded * 100) / totalLength);
                            }
                        }
                    }).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                if (self.upload_step == 0) {
                                    self.configs.slider.push({
                                        link: '',
                                        image: `/uploads/images/${response.data.file.filename}`
                                    });
                                } else {
                                    self.configs.popup_items = [{
                                        link: '',
                                        image: `/uploads/images/${response.data.file.filename}`
                                    }];
                                }
                            break;
                            default:
                                Swal.fire(
                                    'Atenção',
                                    response.data.message,
                                    'error'
                                );
                            break;
                        }
                    }).catch((error) => {
                        try {
                            Swal.fire(
                                'Falha',
                                self.network_erros[error.status]['message'],
                                'error'
                            );
                        } catch(e) {
                            Swal.fire(
                                'Falha',
                                self.network_erros[408]['message'],
                                'error'
                            );
                        } 
                    }).finally(() => {
                        self.upload_progress = false;
                    });
               } 
               else 
               {
                   Swal.fire(
                       'Atenção!',
                       'O formato da imagem é inválido!',
                       'error'
                   );
               }
           }
        },
        getBanks() {

            const self = this;

            if (self.banks.length) return;

            self.bg_loading = true;

            api.get('banks').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.banks = response.data.banks.sort((a, b) => {
                            return a.Name.localeCompare(b.Name);
                        }).map((item) => {
                            return {
                                Name: [item.Code, item.Name].join(' - ')
                            }
                        });
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'error'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.bg_loading = false;
            });
        },
        deleteImage(index) {

            Swal.fire({
                title: 'Excluir imagem',
                text: 'Você deseja mesmo excluir essa imagem?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.value) {

                    if (this.upload_step == 0) {
                        this.configs.slider.splice(index, 1);
                    } else {
                        this.configs.popup_items.splice(index, 1);
                    }
            
                    Swal.fire(
                        'Parabéns!',
                        'A imagem foi excluída com sucesso!',
                        'success'
                    )
                }
            });
        },
        async linkImage(index) {

            try {

                const { value: link } = await Swal.fire({
                    title: 'Adicionar link',
                    html: `Você está adicionando um link na imagem de posição número: ${index + 1}`,
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    customClass: 'sweet-only-custom',
                    inputPlaceholder: 'Informe o link',
                    confirmButtonText: 'Adicionar',
                    inputValidator: (value) => {
                        if (value.toString().trim()) {
                            if (!value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._/+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_/+.~#?&//=]*)/g)) {
                                return 'O link informado não é válido!'
                            }
                        }
                    }
                });

                if (link) {
                    if (this.upload_step == 0) {
                        this.configs.slider[index].link = link;
                    } else {
                        this.configs.popup_items[index].link = link;
                    }
                } else {
                    if (this.upload_step == 0) {
                        this.configs.slider[index].link = '';
                    } else {
                        this.configs.popup_items[index].link = '';
                    }
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha!',
                    'Não é possível adicionar um link nessa imagem!',
                    'error'
                );
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        validateEmail(mail) {
            if (/\S+@\S+\.\S+/.test(mail)) {
                return true
            } else {
                return false;
            }
        },
        noPaste(event) {
            return event.preventDefault();
        },
        virtualSelect(items) {
            window.VirtualSelect.init({
                ele: '#virtual-select',
                search: true,
                placeholder: 'Selecione um banco',
                noOptionsText: 'Nenhuma opção disponível',
                noSearchResultsText: 'Nenhum resultado encontrado',
                searchPlaceholderText: `Buscar banco... (${items.length})`,
                selectedValue: this.configs.dados_bancarios.banco,
                hasOptionDescription: true,
                options: items.map((item) => {
                    return {
                        label: item.Name,
                        value: item.Name
                    }
                })
            });
        },
        async addPaymentLink() {

            try {

                const self = this;

                const { value: formValues } = await Swal.fire({
                    title: 'Adicionar link',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Salvar',
                    html: `
                        <label class="d-block text-left mb-0">Valor</label>
                        <input id="swal-input1" class="swal2-input" autocomplete="off" spellcheck="false" placeholder="Ex: 50">

                        <label class="d-block text-left mb-0">Insira o link</label>
                        <input id="swal-input2" class="swal2-input" autocomplete="off" spellcheck="false" placeholder="Ex: https://mpago.la/1xUS1">
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            valor: document.getElementById('swal-input1').value,
                            link: document.getElementById('swal-input2').value,
                        }
                    },
                    onOpen: () => {

                        document.getElementById("swal-input1").addEventListener("input", (e) => {
                            return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        });

                        document.getElementById("swal-input2").addEventListener("input", (e) => {
                            if (!e.target.value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._/+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_/+.~#?&//=]*)/g)) {
                                return e.target.value = '';
                            } else {
                                return e.target.value;
                            }
                        });
                    }
                });

                if (formValues.valor == '') {
                    Swal.fire(
                        'Atenção!',
                        'Informe o valor do link!',
                        'warning'
                    );
                } else if (!formValues.link.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._/+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_/+.~#?&//=]*)/g)) {
                    Swal.fire(
                        'Atenção!',
                        'O link de pagamento é inválido!',
                        'warning'
                    );
                } else {
                    
                    if (self.configs.links_de_pagamentos.length == 0) {
                        self.configs.links_de_pagamentos.push({
                            valor: formValues.valor,
                            link: formValues.link
                        });
                    } else {
                        if (self.configs.links_de_pagamentos.some((item) => item.valor == formValues.valor)) {
                            Swal.fire(
                                'Atenção!',
                                'Não é permitido adicionar mais de um link com o mesmo valor!',
                                'warning'
                            );
                        } else {
                            self.configs.links_de_pagamentos.push({
                                valor: formValues.valor,
                                link: formValues.link
                            });
                        }
                    }
                }
            } catch(e) {
                window.console.log(e.message);
            }
        },
        deletePaymentLink(index, valor) {
            Swal.fire({
                title: 'Excluir link',
                text: `Você deseja mesmo excluir o link de R$ ${this.formatCoin(valor)} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.value) {

                    this.configs.links_de_pagamentos.splice(index, 1);

                    Swal.fire(
                        'Parabéns!',
                        'O link foi excluído com sucesso!',
                        'success'
                    )
                }
            });
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    computed: {
        nome() {
            return this.configs.nome;
        },
        multiplicador() {
            return this.configs.multiplicador;
        },
        minJogos() {
            return this.configs.min_jogos;
        },
        maxJogos() {
            return this.configs.max_jogos;
        },
        valorMinimo() {
            return this.configs.min_valor;
        },
        valorMaximo() {
            return this.configs.max_valor;
        },
        valorMaximoUmPalpite() {
            return this.configs.max_valor_um_palpite;
        },
        valorMaximoDoisPalpites() {
            return this.configs.max_valor_dois_palpites;
        },
        valorMaximoMaisPalpites() {
            return this.configs.max_valor_mais_palpites;
        },
        minValorOddUmPalpite() {
            return this.configs.min_valor_odd_um_palpite;
        },
        minValorOddMaisPalpites() {
            return this.configs.min_valor_odd_mais_palpites;
        },
        minValorOdd() {
            return this.configs.min_odd;
        },
        excluirApostaTempo() {
            return this.configs.excluir_aposta_tempo;
        },
        mensagem() {
            return this.configs.mensagem;
        },
        regras() {
            return this.configs.regras;
        },
        aoVivoTempo() {
            return this.configs.ao_vivo_tempo;
        },
        aoVivoMinJogos() {
            return this.configs.ao_vivo_min_jogos;
        },
        adiarEventosTempo() {
            return this.configs.adiar_eventos_tempo;
        },
        exibirEventosLimite() {
            return this.configs.exibir_eventos_limite;
        },
        exibirCaixaLimite() {
            return this.configs.exibir_caixa_limite;
        },
        permitirCadastroClientes() {
            return this.configs.permitir_cadastro_clientes;
        },
        dadosBancariosConta() {
            return this.configs.dados_bancarios.conta;
        },
        dadosBancariosAgencia() {
            return this.configs.dados_bancarios.agencia;
        },
        dadosBancariosDigito() {
            return this.configs.dados_bancarios.digito;
        },
        dadosBancariosNome() {
            return this.configs.dados_bancarios.nome;
        },
        dadosBancariosBanco() {
            return this.configs.dados_bancarios.banco;
        },
        valorMinimoSaque() {
            return this.configs.valor_minimo_saque;
        },
        valorMinimoDeposito() {
            return this.configs.valor_minimo_deposito;
        },
        pix() {
            return this.configs.dados_bancarios.pix;
        },
        instagram() {
            return this.configs.redes_sociais.instagram;
        },
        encerrarApostaPorcentagem() {
            return this.configs.encerrar_aposta_porcentagem;
        },
        encerrarApostaNumPalpites() {
            return this.configs.encerrar_aposta_num_palpites;
        },
        notificacoesEmail() {
            return this.configs.notificacoes.email;
        },
        notificacoesValor() {
            return this.configs.notificacoes.valor;
        },
        notificacoesPalpites() {
            return this.configs.notificacoes.palpites;
        },
        comissaoPorIndicacao() {
            return this.configs.comissao_por_indicacao;
        },
        cupomPromocionalCodigo() {
            return this.configs.cupom_promocional_codigo;
        },
        cupomPromocionalValor() {
            return this.configs.cupom_promocional_valor;
        }
    },
    watch: {
        pix(value) {
            try {
                this.configs.dados_bancarios.pix = value.trim();
            } catch (e) {
                //
            }
        },
        instagram(value) {
            try {
                this.configs.redes_sociais.instagram = value.replace(/[^a-z0-9._]/g, '');
            } catch(e) {
                //
            }
        },
        nome(value) {
            try {
                this.configs.nome = value.replace(/[^a-zà-ú0-9\-+\s]+/gi, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        multiplicador(value) {
            try {
                this.configs.multiplicador = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minJogos(value) {
            try {
                this.configs.min_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        maxJogos(value) {
            try {
                this.configs.max_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        comissaoPorIndicacao(value) {
            try {
                this.configs.comissao_por_indicacao = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimo(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_valor = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximo(value) {
            try {
                this.configs.max_valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoUmPalpite(value) {
            try {
                this.configs.max_valor_um_palpite = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoDoisPalpites(value) {
            try {
                this.configs.max_valor_dois_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMaximoMaisPalpites(value) {
            try {
                this.configs.max_valor_mais_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOdd(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_odd = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddUmPalpite(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 

                this.configs.min_valor_odd_um_palpite = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        minValorOddMaisPalpites(value) {

            try {
                value = value.replace(/\D/g,"");  
                value = value.replace(/[0-9]{12}/," ");  
                value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
                value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
                value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2");

                this.configs.min_valor_odd_mais_palpites = value;
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        excluirApostaTempo(value) {
            try {
                this.configs.excluir_aposta_tempo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        mensagem(value) {
            try {
                this.configs.mensagem = value.replace(/[^a-zà-ú0-9(!.,)\-\s]+/gi, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        regras(value) {
            try {
                this.configs.regras = value.replace(/[^a-zà-ú0-9(!.,/)\-\s]+/gi, ''); 
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        aoVivoTempo(value) {
            try {
                this.configs.ao_vivo_tempo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        aoVivoMinJogos(value) {
            try {
                this.configs.ao_vivo_min_jogos = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        adiarEventosTempo(value) {
            try {
                this.configs.adiar_eventos_tempo = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        exibirEventosLimite(value) {
            try {
                this.configs.exibir_eventos_limite = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        exibirCaixaLimite(value) {
            try {
                this.configs.exibir_caixa_limite = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        // permitirCadastroClientes(value) {
        //     if (value == 1 && this.banks.length == 0) {
        //         this.getBanks();
        //     }
        // },
        dadosBancariosConta(value) {
            try {
                this.configs.dados_bancarios.conta = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosAgencia(value) {
            try {
                this.configs.dados_bancarios.agencia = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosDigito(value) {
            try {
                this.configs.dados_bancarios.digito = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        dadosBancariosNome(value) {
            try {
                this.configs.dados_bancarios.nome = value.replace(/[^a-zà-ú\s]+/gi, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimoSaque(value) {
            try {
                this.configs.valor_minimo_saque = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        valorMinimoDeposito(value) {
            try {
                this.configs.valor_minimo_deposito = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        encerrarApostaPorcentagem(value) {
            try {
                this.configs.encerrar_aposta_porcentagem = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        encerrarApostaNumPalpites(value) {
            try {
                this.configs.encerrar_aposta_num_palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        notificacoesEmail(value) {
            try {
                this.configs.notificacoes.email = value.replace(/\s+/g, '');
            } catch(e) {    
                window.console.log(e.message);
            }
        },
        notificacoesValor(value) {
            try {
                this.configs.notificacoes.valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        notificacoesPalpites(value) {
            try {
                this.configs.notificacoes.palpites = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalCodigo(value) {
            try {
                this.configs.cupom_promocional_codigo = value.toString().toLowerCase().replace(/[^a-z0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        cupomPromocionalValor(value) {
            try {
                this.configs.cupom_promocional_valor = value.replace(/[^0-9]/g, '');
            } catch(e) {
                // window.console.log(e.message);
            }
        },
        banks(value) {
            this.virtualSelect(value);
        }
    },
    directives: {
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/[^0-9.]/g,"");
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    mounted() {

        const self = this;

        $(document.body).on('change', '#virtual-select', function() {
            self.configs.dados_bancarios.banco = this.value;
        });
    },
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/configs').then((response) => {
            self.configs = response.data.configs;        
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.form-control.w-auto {
    width: auto!important;
}
.form-group[role="file"] {
    position: relative;
    z-index: 0;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
input#image-upload {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    visibility: hidden;
    text-align: right;
    opacity: 0;
    outline: none;
    background: none;
    cursor: inherit;
    display: block;
}
.myTabConfigs.nav-pills .nav-link {
    border-radius: 0;
    color: var(--white);
    background-color: var(--dark);
}
.myTabConfigs.nav-pills .nav-link.active {
    background-color: var(--red);
}
.col.pl-0 #virtual-select {
    max-width: 100%!important;
}
</style>