<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div v-show="loading == true" class="alert alert-warning" role="alert">
                    <div class="alert-heading text-center mb-0">
                        <div class="spinner-border mr-2 align-middle" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span>Carregando, aguarde...</span>
                    </div>
                </div><!-- /alert-warning -->
                <div v-show="error" class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                    <hr />
                    <p class="mb-0">{{error}}</p>
                </div><!-- /alert-danger -->
                <div class="btn-group w-100">
                    <button type="button" class="btn btn-secondary" @click="cat = 1" :class="{'active': cat == 1}" :disabled="cat == 1">
                        <i class="fas fa-asterisk mr-2" v-show="platform == 'desktop'"></i>
                        <span>Pré-jogo</span>
                    </button>
                    <button type="button" class="btn btn-secondary" @click="cat = 2" :class="{'active': cat == 2}" :disabled="cat == 2">
                        <i class="fas fa-tv mr-2" v-show="platform == 'desktop'"></i>
                        <span>Ao vivo</span>
                    </button>
                </div>
                <div class="mt-3 mb-3" v-if="loading == false">
                    <form @submit.prevent="save">
                        <template v-if="cat == 1">
                            <div class="form-group mb-3" v-for="(item, index) in percents.pre_jogo" :key="index">
                                <label class="text-light" :for="`pregame-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                <input type="text" :id="`pregame-${index}`" v-model="percents.pre_jogo[index]" :readonly="loading == true" @keypress="IsNumber($event, percents.pre_jogo[index])" class="form-control">
                            </div><!-- /form-group -->                            
                        </template>
                        <template v-else>
                            <div class="form-group mb-3" v-for="(item, index) in percents.ao_vivo" :key="index">
                                <label class="text-light" :for="`livegame-${index}`">Comissão {{index}} {{index == 1 ? 'jogo' : index == 20 ? ' jogos ou mais...' : 'jogos'}}</label>
                                <input type="text" :id="`livegame-${index}`" v-model="percents.ao_vivo[index]" :readonly="loading == true" @keypress="IsNumber($event, percents.ao_vivo[index])" class="form-control">
                            </div><!-- /form-group -->   
                        </template>
                        <button class="btn btn-warning btn-block" type="submit">
                            <i class="fas fa-save mr-2"></i>Salvar alterações
                        </button>
                    </form>
                </div>
            </div>
        </div>
      <loading :active.sync="bg_loading" :can-cancel="false" :is-full-page="true"></loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            cat: 1,
            error: '',
            loading: false,
            bg_loading: false,
            percents: {
                pre_jogo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                },
                ao_vivo: {
                    1: 5,
                    2: 7,
                    3: 10,
                    4: 11,
                    5: 12,
                    6: 13,
                    7: 14,
                    8: 15,
                    9: 15,
                    10: 15,
                    11: 15,
                    12: 15,
                    13: 15,
                    14: 15,
                    15: 15,
                    16: 15,
                    17: 15,
                    18: 15,
                    19: 15,
                    20: 15
                }
            },
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        save() {

            const self = this;

            if (self.checkIsValue()) {
                Swal.fire(
                    'Atenção!',
                    'Informe todas as comissões!',
                    'warning'
                );
            } 
            else 
            {
                self.error = '';
                self.bg_loading = true;

                api.post('dashboard/commission-standard', {
                    percents: self.percents
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            ); 
                        break
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.bg_loading = false;
                });
            }
        },
        checkIsValue() {

            let stop = false;

            for (let key in this.percents) {
                
               for (let index in this.percents[key]) {
                   let value = this.percents[key][index];

                   if (value.toString().length == 0) {
                       stop = true;
                       break;
                   } else {
                       this.percents[key][index] = parseInt(value);
                       continue;
                   }
               }
            }

            return stop;
        },
        IsNumber(event) {
            if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
        }
    },
    created() {

        const self = this;

        self.error = '';
        self.loading = true;

        api.get('dashboard/commission-standard').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.percents = response.data.comissoes;
                break;
                default:
                    self.error = response.data.message;
                break;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
</style>