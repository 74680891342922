<template>
    <main class="d-flex justify-content-center align-items-center hv-100 ml-3 mr-3">
        <div class="text-center">
            <span class="text-warning display-1">
                <i class="fal fa-circle-notch fa-spin" v-if="loading"></i>
                <i class="fas fa-comment-alt-lines" v-else></i>
            </span>
            <a class="btn btn-warning btn-block text-uppercase mt-3" href="/">Voltar para o site</a>
            <div class="alert alert-secondary mt-3" role="alert">
                <span v-html="message"></span>
            </div>
        </div>
        <nav class="navbar fixed-bottom navbar-light bg-dark">
            <div class="row w-100">
                <div class="col text-right pr-0">
                    <small class="text-white">Desenvolvido por</small>
                </div>
                <div class="col text-left pl-0">
                    <a href="https://betsnow.net">
                        <img src="https://demo.betsnow.net/images/logo.png" height="30" alt="BetsNOW">
                    </a>
                </div>
            </div>
        </nav>
    </main>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            loading: true,
            message: 'Aperte no <strong>CHAT</strong> abaixo e fale com a gente.',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            },
        }
    },
    methods: {
        setScript(key) {
            let script = document.createElement('script');
            script.src = 'https://code.jivosite.com/widget.js';
            script.setAttribute('async', true);
            script.setAttribute('jv-id', key);
            script.onload = () => {
                this.loading = false;
            };
            document.body.appendChild(script);
        }
    },
    created() {

        const self = this;

        axios.get(`https://api.betsnow.net/chat/${document.location.hostname}`).then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.setScript(response.data.key)
                break;
                default:
                    self.loading = false;
                    self.message = 'O <strong>CHAT</strong> não está disponível!';
                break;
            }
        }).catch((error) => {
            try {
                self.message = self.network_erros[error.status]['message'];
            } catch(e) {
                self.message = self.network_erros[408]['message'];
            }
        });
    }
}
</script>

<style scoped>
.hv-100 {
    height: 100vh;
}
</style>